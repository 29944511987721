var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.menus.header, function(item, index) {
        return [
          _c(
            "v-btn",
            {
              key: index,
              staticClass: "mr-4",
              attrs: {
                dark: "",
                color: "primary darken-1",
                disabled: _vm.$route.path === _vm.$_.get(item, "to", ""),
                to: _vm.$_.get(item, "to", ""),
                href: _vm.$_.get(item, "href", "")
              },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.$emit("header-menu:click", item.id)
                }
              }
            },
            [
              _c("v-icon", { staticClass: "mr-1" }, [
                _vm._v(_vm._s(item.icon))
              ]),
              _c("span", { staticClass: "hidden-sm-and-down" }, [
                _vm._v(_vm._s(item.text))
              ])
            ],
            1
          )
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }