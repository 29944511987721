var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("psi-action-drop-down", {
        attrs: {
          large: "",
          "menu-avatar":
            "https://pacific-screening.imgix.net/profile/photos/047fbf16-2b3a-4675-94ee-e08040ed78cc.jpg?q=90&w=80",
          "heading-avatar":
            "https://pacific-screening.imgix.net/profile/photos/047fbf16-2b3a-4675-94ee-e08040ed78cc.jpg?q=90&w=80",
          items: _vm.userMenu,
          heading: _vm.$_.get(_vm.authUser, "name", ""),
          subheading: _vm.$_.get(_vm.authUser, "company.name", "")
        },
        on: { action: _vm.menuClicked }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }