var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app-bar",
    {
      attrs: {
        app: "",
        "clipped-left": "",
        color: "primary",
        "elevate-on-scroll": ""
      }
    },
    [
      _c("v-app-bar-nav-icon", {
        attrs: { dark: "" },
        on: {
          click: function($event) {
            return _vm.$emit("toggleNav")
          }
        }
      }),
      _c("app-logo"),
      _c("app-search"),
      _c("v-spacer"),
      _c("app-header-menu", {
        on: { "header-menu:click": _vm.headerMenuClick }
      }),
      _c("app-user")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }