<template>
    <v-app-bar app clipped-left color="primary" elevate-on-scroll>
        <v-app-bar-nav-icon
            @click="$emit('toggleNav')"
            dark
        ></v-app-bar-nav-icon>
        <app-logo></app-logo>
        <app-search></app-search>
        <v-spacer></v-spacer>
        <app-header-menu @header-menu:click="headerMenuClick"></app-header-menu>
        <app-user></app-user>
    </v-app-bar>
</template>
<script>
import AppSearch from "@components/search/components/AppSearch.vue";
import AppLogo from "../components/AppLogo.vue";
import AppUser from "@components/users/components/AppUser.vue";
import AppHeaderMenu from "../components/AppHeaderMenu";

export default {
    components: {
        AppLogo,
        AppSearch,
        AppUser,
        AppHeaderMenu,
    },
    name: "app-header",
    data() {
        return {};
    },
    methods: {
        headerMenuClick(slug) {
            console.log(slug);
        },
    },
    mounted() {},
};
</script>

<style scoped>
</style>