<template>
    <!-- menu-icon="mdi-account-circle" -->
    <div>
        <psi-action-drop-down
            large
            menu-avatar="https://pacific-screening.imgix.net/profile/photos/047fbf16-2b3a-4675-94ee-e08040ed78cc.jpg?q=90&w=80"
            heading-avatar="https://pacific-screening.imgix.net/profile/photos/047fbf16-2b3a-4675-94ee-e08040ed78cc.jpg?q=90&w=80"
            :items="userMenu"
            :heading="$_.get(authUser, 'name', '')"
            :subheading="$_.get(authUser, 'company.name', '')"
            @action="menuClicked"
        ></psi-action-drop-down>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    name: "app-user",
    components: {},
    props: {},
    data() {
        return {
            fab: false,
            userMenu: [],
        };
    },
    computed: {
        ...mapGetters("Users", ["menus", "authUser"]),
    },
    watch: {
        menus: {
            immediate: true,
            handler(items) {
                if (this.$_.get(items, "user", []).length > 0) {
                    this.userMenu = items.user.map((item) => {
                        item.class = this.getItemClass(item.id);
                        return item;
                    });
                }
            },
        },
    },
    methods: {
        menuClicked(item) {
            switch (item.id) {
                case "my-account":
                    if (this.$_.get(item, "to", "")) {
                        if (this.$route.path !== item.to) {
                            this.$router.push({ path: item.to });
                        }
                    } else if (this.$_.get(item, "href", "")) {
                        window.location.replace(item.href);
                    }
                    break;
            }
        },
        getItemClass(id) {
            let itemClass = "";
            switch (id) {
                case "my-account":
                    itemClass = "primary--text text--darken-4";
                    break;
                case "logout":
                    itemClass = "error--text";
                    break;
            }
            return itemClass;
        },
    },
};
</script>

<style scoped>
</style>