<template>
    <div class="title ml-3 mr-5 mt-2 hidden-sm-and-down align-center d-flex">
        <span>
            <img :src="$config('app.appLogoIcon')" />
        </span>
        <span class="ml-2 hidden-sm-and-down">
            <img :src="$config('app.appLogoText')" class="mt-1" height="35" />
        </span>
    </div>
</template>
<script>
export default {
    name: "app-logo",
    components: {},
    props: {},
    data() {
        return {};
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {},
};
</script>

<style scoped>
</style>