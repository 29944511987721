<template>
    <div>
        <template v-for="(item, index) in menus.header">
            <v-btn
                :key="index"
                dark
                color="primary darken-1"
                class="mr-4"
                :disabled="$route.path === $_.get(item, 'to', '')"
                :to="$_.get(item, 'to', '')"
                :href="$_.get(item, 'href', '')"
                @click.stop="$emit('header-menu:click', item.id)"
            >
                <v-icon class="mr-1">{{ item.icon }}</v-icon>
                <span class="hidden-sm-and-down">{{ item.text }}</span>
            </v-btn>
        </template>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    name: "app-header-menu",
    components: {},
    props: {},
    data() {
        return {};
    },
    computed: {
        ...mapGetters("Users", ["menus"]),
    },

    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {},
};
</script>

<style scoped>
</style>